import debounce from 'debounce';
import { document, window } from '../globals';
import fetch from '../api/fetch';
import ErrorLogger from '../error-logger/ErrorLogger';

const deactivate = (el: Element) => {
    el.classList.remove('is-open');
    el.classList.remove('is-active');
};

const activate = (el: Element) => {
    el.classList.add('is-open');
    el.classList.add('is-active');
};

const toggle = (el: Element) => {
    el.classList.toggle('is-open');
    el.classList.toggle('is-active');
};

const deactivateAll = (elements: NodeListOf<Element>): void => {
    Array.from(elements).forEach(deactivate);
};

type Hider = (e: Element) => void;
type HideAll = (all: NodeListOf<Element>) => Hider;

const hideOthers: HideAll = (all: NodeListOf<Element>) => (item: Element) => {
    all.forEach((current) => {
        if (current !== item) {
            const childDropdowns = current.parentElement?.querySelectorAll(':scope > .main-nav__dropdown');
            if (childDropdowns) {
                deactivateAll(childDropdowns);
            }

            deactivate(current);
        }
    });
};

export default class Menu {
    private readonly menu: Element | null;

    private readonly menuToggles: NodeListOf<Element> | undefined;

    private readonly backButtons: NodeListOf<Element> | undefined;

    private readonly menuEntryLinks: NodeListOf<Element> | undefined;

    private readonly mainCategoryLinks: NodeListOf<Element> | undefined;

    private languagePickerEntered = false;

    public constructor() {
        this.menu = document?.querySelector('.menu') || null;
        this.menuToggles = document?.querySelectorAll('.main-nav-toggle');
        this.backButtons = document?.querySelectorAll('.main-nav__header-btn--back');
        this.menuEntryLinks = document?.querySelectorAll('.main-nav-link:not(.js-no-dropdown)');
        this.mainCategoryLinks = document?.querySelectorAll('.main-nav__second-link');
    }

    public init(): void {
        this.bindMenuOpenToggle();
        this.bindBackToggle();
        this.bindDropdownToggles();
        this.bindLanguagePickerHover();
        Menu.bindAnalyticsEvents();
    }

    private bindMenuOpenToggle(): void {
        if (!document || !window) {
            return;
        }
        const body = document.querySelector('body');
        const menuBackdrop = document.querySelector('.menu__backdrop');

        if (!body || !menuBackdrop) {
            return;
        }

        this.menuToggles?.forEach(
            (item) => item.addEventListener('click', () => {
                this.menu?.classList.toggle('is-open');
                document?.body?.classList.toggle('menu-open');
                body.classList.toggle('util__noScroll');
                body.classList.toggle('util__noScroll--noScrollbars');
            }),
        );

        menuBackdrop.addEventListener('click', (e) => {
            if (!document) {
                return;
            }
            const { target } = e;
            if (!(target instanceof Element) || this.menu === null || !this.menuToggles) {
                return;
            }

            const isToggle = Array.from(this.menuToggles).some(
                (element) => element.contains(target),
            );

            if (this.menu.contains(target) || isToggle) {
                return;
            }

            body.classList.remove('util__noScroll');
            body.classList.remove('util__noScroll--noScrollbars');

            deactivate(this.menu);
            deactivateAll(
                document.querySelectorAll(
                    '.main-nav-link, .main-nav__second-link, .main-nav__dropdown',
                ),
            );
        });

        // This removes all classes when you suddenly change from mobile to desktop
        window?.matchMedia('(min-width: 992px)').addListener(debounce((e: MediaQueryListEvent) => {
            if (!document) {
                return;
            }
            if (e.matches) {
                deactivateAll(
                    document.querySelectorAll(
                        '.menu, .main-nav-link, .main-nav__second-link, .main-nav__dropdown',
                    ),
                );
            }
        }, 2000));
    }

    private bindBackToggle(): void {
        if (!this.menuEntryLinks || !this.mainCategoryLinks) {
            return;
        }
        const allToggles = new Set([
            ...this.menuEntryLinks,
            ...this.mainCategoryLinks,
        ]);

        const dropDowns = document?.querySelectorAll('.main-nav__dropdown') || [];
        const menu = document?.querySelector('.menu');

        this.backButtons?.forEach((item) => item.addEventListener('click', () => {
            allToggles.forEach(deactivate);
            dropDowns.forEach(deactivate);
            item.classList.add('semi-hidden');
            menu?.classList.remove('no-scroll');
        }));
    }

    private bindDropdownToggles(): void {
        this.menuEntryLinks?.forEach(this.toggleElement(hideOthers(this.menuEntryLinks)));
        this.mainCategoryLinks?.forEach(this.toggleElement(() => null));
    }

    private toggleElement = (hideOtherElements: Hider) => (item: Element): void => {
        const menu = document?.querySelector('.menu');
        const dropdown = item.parentElement?.querySelector('.main-nav__dropdown');
        let otherDropdowns: NodeListOf<Element> | undefined;
        /**
         * :scope selector may not be avaialbe on some older browsers. (IE11).
         * For these browsers we just keep the old tabs open when a new one is clicked.
         *
         * Without the catch we wouldn't be able to toggle the menu at all.
         * @see https://caniuse.com/?search=%3Ascope
         */
        try {
            otherDropdowns = item.parentElement?.parentElement?.querySelectorAll(':scope > li > .main-nav__dropdown');
        } catch (e) {
            otherDropdowns = undefined;
        }

        const hideOtherDropdowns = otherDropdowns
            ? hideOthers(otherDropdowns)
            : () => null;

        item.addEventListener('click', (e) => {
            if (!dropdown) {
                return;
            }
            if (!document) {
                return;
            }

            e.preventDefault();

            if (item.classList.contains('is-open')) {
                toggle(item);
                toggle(dropdown);
            } else {
                deactivateAll(
                    document.querySelectorAll(
                        '.main-nav__second-link, .main-nav__dropdown--second',
                    ),
                );
                activate(item);
                dropdown.classList.add('is-open');
            }

            hideOtherElements(item);
            this.backButtons?.forEach((element) => {
                element.classList.remove('semi-hidden');
            });

            if (dropdown) {
                hideOtherDropdowns(dropdown);
            }

            if (!menu) {
                return;
            }
            if (dropdown.classList.contains('is-open')) {
                menu.classList.add('no-scroll');
                menu.scrollTop = 0;
            } else {
                menu.classList.remove('no-scroll');
            }
        });
    };

    private static bindAnalyticsEvents(): void {
        const determineLevel = (element: HTMLAnchorElement): number => {
            const level = Number(element.dataset.linkLevel);

            return isNaN(level) ? 3 : level;
        };

        Array.from(document?.querySelectorAll('.js-menu-items') ?? []).forEach((container) => {
            if (!(container instanceof HTMLElement)) {
                return;
            }

            container.addEventListener('click', (event: MouseEvent) => {
                if (event.defaultPrevented) {
                    // This event is prevented, so it is used as a drop down on mobile
                    return;
                }
                const { target } = event;

                const linkElement = target instanceof HTMLElement && target.closest('a');

                if (!(linkElement instanceof HTMLAnchorElement) || !container.contains(linkElement)) {
                    return;
                }
                const level = determineLevel(linkElement);

                window?.analytics?.track('Element Clicked', {
                    category: 'Menu Link',
                    label: level,
                    level,
                    link: linkElement.href,
                    text: linkElement.innerText,
                });
            });
        });
    }

    private bindLanguagePickerHover(): void {
        const pageId = document?.getElementById('pageIdentifier')?.innerHTML;
        const countryListElement = document?.getElementById('js-country-list');
        if (!pageId || !countryListElement) {
            return;
        }
        const listener = () => {
            if (this.languagePickerEntered || !pageId) {
                return;
            }
            this.languagePickerEntered = true;

            fetch(`/urls-by-context?pageId=${pageId}`)
                .then((body) => body.json())
                .then((json: Record<string, string>) => {
                    Object.entries(json).forEach(([context, url]) => {
                        document?.getElementById(`context-${context}`)?.setAttribute('href', url);
                    });
                }).catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
        };

        countryListElement.addEventListener('mouseenter', listener);
        countryListElement.addEventListener('click', listener);
    }
}
